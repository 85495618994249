input[type="number"] {
  color: black;
  width: 3rem;
  text-align: left;
}
input[type="text"] {
  color: black;
  text-align: left;
}

input[type="text"]:disabled {
  background: white;
}

.done-button {
  margin: 1rem auto;
  position: relative;
  font-size: 1.2rem;
  border-radius: 1rem;
}
