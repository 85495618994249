.active {
  background-color: #f7f7f7;
}
.modal {
  color: black;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  text-align: center;
  position: relative;
  background-color: rgba(14, 26, 64, 0.98);
  margin: 15% auto;
  padding: 20px;
  width: 80%;
  max-width: 50rem;
}

.new-question label {
  font-size: 1.5rem;
}

.input-field {
  margin: 10px 0;
  height: 40px;
  width: 100%;
  font-size: 1.25rem;
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.button-group > .submit-button {
  width: 100%;
  margin-top: 30px;
}
