@keyframes blinker {
  50% {
    opacity: 0;
  }
}
* {
  color: rgb(255, 255, 255);
}
h1 {
  text-align: center;
}
html {
  background-color: #5743c9;
}

div {
  border-radius: 6px;
}

input,
button {
  color: black;
  border-radius: 3px;
}
button,
.button {
  border-radius: 3px;
  border: none;
  color: rgb(0, 0, 0);
  outline: none;
  background-color: #fdb542;
  padding: 1em 1.5em;
  text-decoration: none;
  box-sizing: content-box;
  cursor: pointer;
}
.clickable {
  cursor: pointer;
}
button:disabled {
  opacity: 0.6;
}
.red {
  background: #b85b57;
  color: white;
}
@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
}

ol li {
  margin-top: 0.5rem;
}

.spinner {
  display: inline-block;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
