.footer {
  position: fixed;
  display: flex;
  align-items: baseline;
  bottom: 0;
  left: 5%;
  right: 5%;
}

.footer__sponsor-text {
  bottom: 0;
  left: 5%;
  align-items: baseline;
  align-content: baseline;
}

.footer__sponsor-link {
  margin: 0 5px;
}

.footer__sponsor-logo {
  margin: 5px;
  height: 25px;
}

.footer__copyright-text {
  right: 0%;
  left: 0%;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  align-items: baseline;
  align-content: baseline;
  gap: 10px;
}

.footer__source-code {
  display: flex;
  bottom: 0;
  right: 5%;
  align-items: baseline;
  align-content: baseline;
  gap: 10px;
}
