.close-button {
  box-sizing: content-box;
  display: block;
  position: absolute;
  top: 1rem;
  right: 1rem;
  height: 1rem;
  width: 1rem;
  line-height: 1rem;
  border-radius: 100%;
  background: none;
  color: white;
  border: 1px solid white;
  text-align: center;
  cursor: pointer;
  padding: 0.1rem;
  font-size: 1rem;
}
